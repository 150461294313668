<template>
  <div class="main">
    <div class="table">
      <el-table :data="tableData" highlight-current-row>
        <el-table-column label="id" width="200">
          <template slot-scope="scope">
            <div v-html="scope.row.id"></div>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="title" width="200"></el-table-column>
        <el-table-column prop="remark" label="remark" width="200"></el-table-column>
        <el-table-column prop="updateTime" :formatter="formatTime" label="更新时间" width="200"></el-table-column>
        <el-table-column label="操作" width="250">
          <template slot-scope="scope">
            <el-button size="small" @click="onEdit(scope.$index, scope.row)">编辑</el-button>
            <el-button type="danger" size="small" @click="onDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import msgLogger from "@/util/msgLogger";
import { csConfigApi } from "@/api/csConfigApi";
import dayjs from "dayjs";

export default {
  components: {},
  data() {
    return {
      tableData: [],
    };
  },
  methods: {
    onQuery() {
      this.tableData = [];
      csConfigApi.pager(0, 1000).then((ret) => {
        console.log("#1", ret);
        let { code, data } = ret;
        if (code == 0) {
          this.tableData = data;
        }
      });
    },
    onEdit(index, row) {
      let path = `/cs-config/edit?id=${row.id}`;
      let routeData = this.$router.resolve({ path: path });
      window.open(routeData.href, "_blank");
    },
    onDelete(index, row) {
      let id = row.id;
      let msg = "确定删除该项吗？";
      this.$confirm(msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          csConfigApi.deleteById(id).then((ret) => {
            console.log(ret);
            if (ret.code == 0 && ret.data) {
              msgLogger.done("成功地删除了");
              this.onQuery();
            } else {
              msgLogger.error(ret.msg);
            }
          });
        })
        .catch(() => {});
    },
    formatTime: function(row, column) {
      let num = Number(row[column.property]);
      let day = dayjs(Number(num));
      return day.format("YYYY-MM-DD hh:mm");
    },
  },
  mounted() {
    this.onQuery();
  },
};
</script>

<style lang="less" scoped>
.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  .box-card {
    text-align: left;
    margin: 20px 0px;
  }
  .button-row {
    text-align: right;
    margin: 20px;
  }
  .table {
    width: 90%;
  }
}
</style>
