import ajax from "./ajax.js";
import Qs from "qs";

let base = process.env.VUE_APP_BASE_URL;

const addCsConfig = (csConfig) => {
  return ajax.post(`${base}/api/itedu/v1/cs-config/add`, csConfig);
};

const getById = (id) => {
  return ajax.get(`${base}/api/itedu/v1/cs-config/get?id=${id}`);
};

const pager = (start, len) => {
  return ajax.get(`${base}/api/itedu/v1/cs-config/pager?start=${start}&len=${len}`);
};

const deleteById = (id) => {
  return ajax.post(`${base}/api/itedu/v1/cs-config/delete?id=${id}`);
};

export const csConfigApi = {
  addCsConfig: addCsConfig,
  getById: getById,
  pager: pager,
  deleteById: deleteById,
};
